import config from 'jsfcore/helpers/clientConfig';
import { getAuthProps, updateWebsocketClientOnAuth } from './auth';

const events = {
  CONNECT: 'CONNECT',
  CLOSE: 'CLOSE',
  ERROR: 'ERROR',
  SERVER_ERROR: 'SERVER_ERROR',
  AUTH: 'AUTH',
  OPERATIONS: 'OPERATIONS',
  DESTROY: 'DESTROY',
};

const eventHandlers = {};

const subscribePromises = Object.keys(events).reduce((acc, event) => {
  let resolve;
  const promise = new Promise((res) => {
    resolve = res;
  });
  return {
    ...acc,
    [event]: {
      promise,
      resolve,
      messages: [],
    },
  };
}, {});

const eventsCallbacks = Object.keys(events)
  .map((event) => {
    return {
      event,
      callback: (...args) => {
        return subscribePromises[event].messages.push(args);
      },
    };
  });

const promisesArray = Object.keys(subscribePromises)
  .map((key) => {
    return subscribePromises[key].promise;
  });

Promise.all(promisesArray).then((args) => {
  args.forEach(({ type, listener }) => {
    subscribePromises[type].messages.forEach((message) => {
      listener(...message);
    });
  });
});

const makeOn = (on) => {
  return (type, listener) => {
    subscribePromises[type].resolve({ type, listener });
    on(type, listener);
  };
};

const handle = () => {
  return (event, handler) => {
    if (!eventHandlers[event]) {
      eventHandlers[event] = [];
    }

    eventHandlers[event].push(handler);
  };
};

const fake = ({ on, connect, removeListener }) => {
  connect(config.ws.config);
  eventsCallbacks.forEach(({ event, callback }) => {
    on(event, (...args) => {
      callback(...args);

      if (eventHandlers[event]) {
        eventHandlers[event].forEach((handler) => {
          handler(...args);
        });
      }
    });
  });
  Promise.all(promisesArray).then(() => {
    eventsCallbacks.forEach(({ event, callback }) => {
      removeListener(event, callback);
    });
  });
};

function operationsHolder(websocketClient) {
  const on = websocketClient.on.bind(websocketClient);
  const removeListener = websocketClient.removeListener.bind(websocketClient);
  const send = websocketClient.send.bind(websocketClient);
  const startPing = websocketClient.startPing.bind(websocketClient);
  const connect = websocketClient.connect.bind(websocketClient);

  on(events.CONNECT, () => {
    startPing({ reconnectTimeout: 10000, sendInterval: 5000 });
    send({
      auth: {
        properties: getAuthProps(websocketClient),
      },
    });
  });

  // обновить значения websocketClient
  updateWebsocketClientOnAuth(websocketClient, getAuthProps(websocketClient));
  fake({ on, send, startPing, connect, removeListener, websocketClient });

  // eslint-disable-next-line no-param-reassign
  websocketClient.on = makeOn(on);

  // eslint-disable-next-line no-param-reassign
  websocketClient.handle = handle();

  return websocketClient;
}

export default operationsHolder;
