import React from 'react';
import ReactDOM from 'react-dom';
import websocketClient from 'websocket-client';

import Loader from './components/Loader';
import LoaderView from './components/LoaderView';
import { storeLoaderInstance } from './instanceStorage';
import { initializeJSFillerStores, sessionsManager } from './JSFillerStores';
import { getQSParams, getAuthProps } from './auth';
import operationsHolder from './operationsHolder';

function initializeWebsocket() {
  const {
    embedded,
    isOfflineMode,
  } = getQSParams();

  if (isOfflineMode) {
    sessionsManager.createSession();
    return;
  }

  if (embedded) {
    // для AS мы создаем сессию только через interfaceSaga (JSFiller.open)
    return;
  }

  const ws = operationsHolder(websocketClient);
  const { projectId, viewerId } = getAuthProps(websocketClient);

  sessionsManager.createSession(ws, { projectId, viewerId });
}

function render(LoaderViewOverride) {
  storeLoaderInstance(
    ReactDOM.hydrate(
      <Loader>
        {LoaderViewOverride
          ? <LoaderViewOverride />
          : <LoaderView />
        }
      </Loader>,
      document.getElementById('loader'),
    ),
  );
}

export {
  initializeWebsocket,
  initializeJSFillerStores,
};

export default render;
