import PropTypes from 'prop-types';
import React from 'react';
import cx from 'classnames';

export const calcLoaderImgClassNames = (steps, progress) => {
  return (
    steps.reduce(
      (acc, val, index) => {
        return {
          ...acc,
          [`document-loader__img--step-${index + 1}`]: (
            progress >= val.startPercent &&
            (
              progress < val.startPercent + val.percent ||
              val.startPercent + val.percent === 100
            )
          ),
        };
      }, {})
  );
};

function LoaderView(props) {
  const {
    progress,
    steps,
  } = props;
  return (
    <div className="document-loader-overlay">
      <div className="document-loader">
        <div className="document-loader__title">Loading, Please wait a moment…</div>
        <div className="document-loader-row">
          <div className="document-loader-row_cell document-loader-row_cell--img">
            <div
              className={cx(
                'document-loader__img',
                calcLoaderImgClassNames(steps, progress),
              )}
            />
            <div className="document-loader__title document-loader__title--mobile">
              Loading
              <div>Please wait a moment…</div>
            </div>
          </div>
          <div className="document-loader-row_cell">
            <ul className="document-loader-list">
              {steps.map(({ text, percent, startPercent }) => {
                return (
                  <li
                    className={cx({
                      'document-loader-list-item': true,
                      'document-loader-list-item--loading':
                        progress >= startPercent &&
                        progress < startPercent + percent,
                      'document-loader-list-item--loaded':
                        progress >= startPercent + percent,
                    })}
                    key={percent}
                  >
                    {text}
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
        <div className="document-loader-progress">
          <div
            style={{
              width: `${progress}%`,
            }}
            className="document-loader-progress__inner"
          />
        </div>
      </div>
    </div>
  );
}

LoaderView.propTypes = {
  progress: PropTypes.number,
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      percent: PropTypes.number.isRequired,
      startPercent: PropTypes.number.isRequired,
    }).isRequired,
  ),
};

LoaderView.defaultProps = {
  progress: 0,
  steps: [],
};

export default LoaderView;
