import PropTypes from 'prop-types';
import { Component, cloneElement } from 'react';
import findLastIndex from 'lodash/findLastIndex';

const EXPECTED_MAX_TIME_PER_STEP = 20000;
const UPDATE_FREQ = 50;

// позже вынести в locale файлы
// также в render вынести строки Loading, "Please wait a moment…"
// ---
// для удобной конвертации процента шагов и процента общего прогресса
// заводим ключ startPercent начала каждого шага на фоне общего прогрессбара
export const countStartPercents = (steps) => {
  return steps.reduce((acc, val, index, arr) => {
    return [
      ...acc,
      {
        ...val,
        startPercent: index
          ? acc[index - 1].startPercent + arr[index - 1].percent
          : 0,
      },
    ];
  }, []);
};

const defaultStepsValues = countStartPercents([{
  timingFunction: (t) => {
    return t * (2 - t);
  },
  text: 'Establishing secure connection',
  percent: 60,
}, {
  text: 'Loading PDF editor',
  percent: 30,
}, {
  timingFunction: (t) => {
    return 1 - ((t - 1) ** 4);
  },
  text: 'Loading document',
  percent: 10,
}]);


// индексы в stepsIndexes указывают порядок загрузки
// несмотря на то, что сначала идет загрузка документа, а потом редактора
// мы юзеру говорим о том, что сначала грузится редактор, потом документ

export default class Loader extends Component {
  static propTypes = {
    children: PropTypes.element.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      progress: 0,
      hidden: false,
      finished: false,
      steps: defaultStepsValues,
    };

    this.stepsIndexes = {
      connection: 0,
      document: 1,
      editor: 2,
    };

    this.ticker = null;
    this.tickFn = null;
    this.tickValue = 0;
  }


  componentDidMount() {
    this.startTicker();
    this.setStep({
      stepIndex: this.stepsIndexes.connection,
    });
  }

  componentWillUnmount() {
    clearTimeout(this.ticker);
  }

  setStep = ({ stepIndex, startValue = 0, persist = false }) => {
    const { timingFunction } = this.state.steps[stepIndex];
    if (!persist && timingFunction) {
      this.resetTicker(
        startValue,
        (value) => {
          return this.setStepProgress({
            percent: timingFunction(value) * 100,
            stepIndex,
          });
        },
      );
    } else {
      this.resetTicker();
      this.setStepProgress({
        percent: startValue,
        stepIndex,
        persist,
      });
    }
  };

  setStepProgress = ({ stepIndex, percent, persist = false }) => {
    if (__CLIENT__ && window.loader_progress !== undefined && !persist) {
      return;
    }
    this.setState((prevState) => {
      const thatStep = prevState.steps[stepIndex];
      return {
        progress: thatStep.startPercent + ((thatStep.percent * percent) / 100),
      };
    });
  };

  setProgress(progress, persist = false) {
    const { steps } = this.state;
    const foundStepIndex = findLastIndex(steps, (el) => {
      return el.startPercent < progress;
    });
    const foundStep = steps[foundStepIndex];
    this.setStep({
      stepIndex: foundStepIndex,
      percent: progress - (foundStep.startPercent / foundStep.percent),
      persist,
    });
  }

  resetTicker(tick = 0, fn = null) {
    this.tickValue = tick;
    this.tickFn = fn;
  }

  stopTicker() {
    if (!this.ticker) {
      return;
    }

    this.resetTicker(0, null);
    clearInterval(this.ticker);
    this.ticker = null;
  }

  startTicker() {
    if (this.ticker) {
      return;
    }

    this.ticker = setInterval(() => {
      if (!this.hidden && !this.handleWindowLoaderProgress() && this.tickFn) {
        const val = this.tickValue / (EXPECTED_MAX_TIME_PER_STEP / UPDATE_FREQ);
        if (val <= 1) {
          this.tickFn(val);
          this.tickValue += 1;
        }
      }
    }, UPDATE_FREQ);
  }

  handleWindowLoaderProgress() {
    const loaderProgressExist = __CLIENT__ && window.loader_progress !== undefined;

    if (loaderProgressExist) {
      this.setProgress(window.loader_progress, true);
    }

    return loaderProgressExist;
  }

  finish = () => {
    this.stopTicker();
    this.setState({
      progress: 100,
      finished: true,
    });
  };

  hide = () => {
    this.setState({
      hidden: true,
    });
  };

  show = () => {
    this.resetTicker(0, null);
    this.startTicker();
    this.setState({
      hidden: false,
    });
  };

  start = () => {
    this.resetTicker(0, null);
    this.setState({
      hidden: false,
      progress: 0,
      finished: false,
    });
    this.startTicker();
    this.setStep({
      stepIndex: this.stepsIndexes.connection,
    });
  };

  render() {
    if (this.state.hidden || this.state.finished) {
      return null;
    }
    const { progress, steps } = this.state;
    return cloneElement(this.props.children, { progress, steps });
  }
}
